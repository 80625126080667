/* modal.css
 * created 10/14/13
 --------------------------------------------------------- */

.modal-dialog {

  .modal-options {
    margin-bottom:15px;
  }

  .tag {
    padding-top: 5px;
    margin-right: 5px;
    margin-bottom: 8px;
    font-size: 8pt;
    font-weight: 700;
    width: 115px;
    &.image-info-label {
      width: 100%;
    }
  }

  .tt-hint {
    width: 438px;
    background-color: white;
  }


  #sendDestination, #routeAsSelect {
    width: 438px;
  }
}

.modal-content {
  h1, h2, h3, h4, h5, h6, p, li a button {
    border-radius: 0;
    font-family: "Open Sans" !important;
    color: #444;
  }

  .close:hover {
    opacity:0.7
  }
}

button.btn-close-modal {
  border-radius: 0;
  background: transparent;
  color: #888;
  font-size: 9pt;
  border:0;
}

button.btn-close-modal:hover {
  background: none;
  text-decoration: underline;
  box-shadow: 0 0 0;
}

/* Complete Task
 * -------------------------------------------------- */
.task-ops textarea {
  width: 100%;
}

.task-ops button.btn-close-modal:hover {
  color: inherit;
}


/* Profile
 * -------------------------------------------------- */
.password-fields {
  width: 48%;
}

.password-tips {
  width: 48%;
}

.password-tips li {
  font-size: 9pt;
  padding-bottom:5px;
}

li.head {
  font-weight: 800;
  list-style: none outside none;
  margin: 0 0 10px -15px;
}

/* Edit Authors
 * -------------------------------------------------- */

.author-selection {
  min-height: 35px;
}

.author-selection .tag {
  width: 120px;
}

.warning-block {
  padding-top: 10px;

  i {
    padding-top: 3px;
  }
  p {
    padding-left: 10px;
  }
}

.modal-options {
  .author-wrapper {
    .author-area {
      width: 558px;
      &:not(:nth-child(2)) {
        margin-top: 5px;
      }
    }
  }

  button.add-author-option {
    margin-top: 5px;
  }
}
.processing-warning {
  padding: 4px 6px;
  margin-bottom: 0;
}

/* Send
 * -------------------------------------------------- */

#flags-holder {
  width: 558px;
  min-height: 33px;
  margin-top: 10px;
}

.copy-level-wrapper {
  margin-top: 10px;
}

#pin-assignments {
  width: 558px;
  height: 33px;
}

.bootbox .modal-dialog {
  width: 300px;
  height: 400px;
}

.bootbox .modal-dialog .btn-success {
  margin-left: 10px;
}

/* Spell Check
 * -------------------------------------------------- */

#spellCheckModal {
  .modal-dialog {
    color: #444;
    font-family: "Open Sans" !important;
    background-color: white;
    border-radius: 4px;
    width: 685px;

    .modal-body {
      padding: 0 20px 20px 20px;

      #context-title {
        font-size: 10pt;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    .modal-header #correction-num {
      width: 100px;
      height: 25px;
      margin-left: 10px;
      font-size: 16px;

      div {
        margin-right: 3px;
      }
    }
  }

  .box-container {

    #correction-box {
      border: solid black 1px;

      #content-text {
        padding: 5px;
        min-height: 130px;
        max-height: 130px;
        width: 435px;

        span.incorrect {
          color: red;
          background-color: yellow;
        }

        i {
          background-image: none;
          background-repeat: no-repeat;
          width: intial !important;
          height: intial !important;
        }
      }
    }

    #suggestion-box {
      padding: 5px;
      border: solid black 1px;
      min-height: 130px;
      max-height: 130px;
      width: 200px;

      .suggestion-selection {
        cursor: pointer;
      }
      .active {
        background-color: lightskyblue;
      }
      .no-suggestions {
        text-align: center;
      }
    }
  }

  #correctionButtons {
    margin-top: 10px;

    button.push-lft {
      margin-right: 5px;
    }
  }

}

.image-modal-content {
  width: 682px;
}

/* Find and Replace
 * -------------------------------------------------- */

#findReplaceModal {
  .modal-dialog {
    color: #444;
    font-family: "Open Sans" !important;
    background-color: white;
    border-radius: 4px;
    width: 685px;

    .modal-header #correction-num {
      width: 100px;
      height: 25px;
      margin-left: 10px;
      font-size: 16px;

      div {
        margin-right: 3px;
      }
    }

    .modal-body {
      padding: 0 20px 20px 20px;

      .search-area {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 8px;

        .find-wrapper {
          border-right: 1px solid #e5e5e5;
          padding-right: 15px;
        }

        .replace-wrapper {
          padding-left: 15px;
        }

        .input-label {
          margin-top: 10px;
          margin-bottom: 2px;
        }

        .input-term {
          color: #444;
        }

        .search-button, .swap-button {
          padding: 6px 12px;
          font-size: 14px;
        }
      }

      .result-area {
        #result-context-title {
          font-size: 10pt;
          font-weight: 600;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .box-container {
          #result-box {
            border: solid black 1px;

            #result-text {
              padding: 5px;
              min-height: 130px;
              max-height: 130px;
              font-weight: 400;

              span.replace-term {
                background-color: #FFFFA4;
              }

              i {
                background-image: none;
                background-repeat: no-repeat;
                width: intial !important;
                height: intial !important;
              }
            }
          }
        }

        #resultButtons {
          margin-top: 10px;
        }
      }
    }


  }
}

/* Action Modal
 * -------------------------------------------------- */

#action-modal, #complete-tasks, #author-modal {
  .modal-options {
    display: inline-block;
    div {
      display: inline-block;
    }
    .chosen-search {
      width: 100%;
    }
  }
}

/* Add Topic Modal
 * -------------------------------------------------- */

#add-topic {
  .modal-dialog {
    width: 800px;

    .modal-body {
      padding: 10px;
    }

    .tree-area {
      width: 49%;
      height: 100%;

      select {
        width: 100%;
        height: 25px;
        font-size: 12pt;
        font-weight: 600;
      }

      #topic-trees {
        height: 280px;

        a.toggle-data {
          i.folder-selected {
            color: white;
            margin-right: 5px;
            display: none;
          }
        }

        a.toggle-data:hover{
          background-color: #F0F1F3;
        }

        a.toggle-data.selected {
          background-color: #808080;
          span {
            color: white
          }

          i.folder-selected {
            display: block;
          }
        }

        .error-message {
          font-weight: bold;
        }

        .data-header {
          padding: 0;
          border: none;
          background: none;
        }

        .nested {
          margin-left: 15px;
        }

        .data-body {
          padding: 0;
        }


      }
    }

    .topic-search {
      width: 49%;
      height: 100%;

      #topic-input {
        width: 100%;
        height: 25px;
        font-size: 12pt;
        font-weight: 600;
      }

      #topic-area {
        height: 280px;

        .data-header {
          background-color: white;
        }

        .data-body {
          padding: 0;
        }

        .leafNode {
          .topic-selected {
            color: white;
            margin-right: 5px;
          }

          .topic-selected {
            display: none;
          }
        }

        .leafNode:hover {
          background-color: #F0F1F3;
        }

        .leafNode.selected {
          background-color: #808080;
          span {
            color: white
          }

          .topic-selected {
            display: block;
          }
        }
      }
    }


  }
}

/* Video Editor Modal
 * -------------------------------------------------- */
#videoEditor {

  .modal-dialog {
    width: 848px;
    background: #222;
    color: white !important;

    .modal-body {
      padding: 10px;

      .video-player-outer {
        width: 500px;
        position: relative;

        .video-overlay {
          width: 100%;
          height: 500px;
          position: relative;
          display:flex;
          align-items:center;
          justify-content: center;
          cursor: pointer;


          > .video-overlay-image {
            width: 100%;
            height: 100%;

            > .video-play-icon {
              position: absolute;
              top: 200px;
              left: 200px;
              font-size: 100px;
              transition: color 0.2s;

              &:hover {
                color: #428bca;
              }
            }

            > img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .video-player {
          width: 500px;
          height: 500px;
        }

      }

      .video-info {
        width: 296px;
        margin-left: 30px;

        h3 {
          border-bottom: 1px solid #444444;
          color: #FFFFFF;
          font-size: 11pt;
          font-weight: 700;
          padding: 0 0 5px;
          margin-bottom: 0;
          margin-top: 0;

          &.text-area {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }

        .option-item {
          margin: 5px 0;
          width: 100%;

          .tag {
            display: block;
            color: #ddd;
            margin: 1px 0;
          }
        }

        .caption-edit {
          min-height: 200px;
          height: 200px;
          overflow-y: auto;
        }
      }

      .done-button {
        margin-top: 5px;
        float: right;
      }
    }
  }
}

/* Send Modal
 * -------------------------------------------------- */
#send-modal {
  #myModalLabel {
    display: inline;
  }

  .modal-options div {
    display: inline-block;
  }

  .task-ops textarea {
    width: 100%;
    min-height: 70px;
  }

  .indicator-steps {
    display: inline-block;
    vertical-align: bottom;
    width: 500px;

    .indicator-wrapper {
      margin: 0 auto;

      .tooltip  {
        height: auto !important;
        background: none;
      }

      .indicator-step-icon {
        height: 24px;
        line-height: 23px;
        width: 37px;
        background-color: white;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-left: 1px solid #ddd;
        display: inline-block;
        text-align: center;
        cursor: default;

        &:last-child {
          border-right: 1px solid #ddd;
        }

        &.current-step {
          background-color: lighten(#778899, 20%);
        }
        &.active {
          background-color: #FFFFD9;
          border: 1px solid red;
        }
      }
    }
  }
}

#complete-notes {
  min-height: 70px;
}

/* Group Images Modal
 * -------------------------------------------------- */
#group-images, #add-figure-reference {

  .modal-header {
    .errorMessage {
      height: 100%;
      margin-left: 20px;
      margin-bottom: 0;
      padding: 2px 15px;
    }
  }
  .modal-body {
    height: 700px;
    padding: 0;

    .col-md-10, .col-md-2 {
      padding: 0;
    }

    #pages {
      padding-right: 20px;
      padding-left: 20px;
      max-height: 700px;
      height: 700px;
      border-right: 1px solid #e5e5e5;

      .image-group {
        margin-top: 10px;

        &:last-child {
          margin-bottom: 10px;
        }

        .image-group-header {
          background-color: #f0f1f3;
          border: 1px #c2c2c2 solid;
          color: #2b2e37;
          font-size: 10pt;
          font-weight: 600;
          margin-bottom: 5px;
          padding: 5px 7px;
          width: 100%;
        }

        .image-group-images {

          .wrapper {
            height: 130px;
            margin: 2px;
          }

          .group-image {
            border: 3px solid white;

            &:hover {
              padding: 0;
              border: 3px solid #5cb85c;
            }
            &.referenced {
              border: 3px solid #f0ad4e;
            }
            &.selected {
              border: 3px solid #428bca;
            }
          }
        }
      }
      .page {
        width: 360px;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;
        background-color: #f3f3f3;

        .page-header {
          margin: 10px 0 10px;
          padding-left: 10px;
          border-bottom-color: #e5e5e5;
        }

        .page-images {
          margin-bottom: 10px;

          .wrapper {
            width:  50%;
            height: 130px;
          }
          .page-image {
            width: 126px;
            height:126px;
            margin: 5px;
            border: 3px solid #f3f3f3;
            margin-left:  auto;
            margin-right: auto;
            cursor: pointer;
            position: relative;

            .group-letter {
              position: absolute;
              z-index: 0;
              visibility: hidden;
              top: 100px;
              left: 100px;
              font-size: 15px;
              text-align: center;
              height: 20px;
              width: 20px;
              background-color: #000;
              color: #fff;
            }

            &.valid-for-group {
              padding: 1px;
              border: 2px dotted #428bca;
            }
            &:hover {
              padding: 0;
              border: 3px solid #5cb85c;
            }
            &.grouped, &.referenced {
              border: 3px solid #f0ad4e;
            }
            &.selected {
              border: 3px solid #428bca;
            }
            &.show-letter {
              .group-letter {
                z-index: 100;
                visibility: visible;
              }
            }
            &.invalid-for-group {
              &:hover {
                border: 3px solid #f3f3f3;
                cursor: default;
              }
            }
            &.no-image-id {
              height: 120px;
              border: 1px solid #000;

              &:hover {
                border: 1px solid #000;
              }
            }
          }
        }
      }
    }

    #controls {
      height: 700px;
      width: 100%;
      background-color: #f3f3f3;
      padding-top: 20px;

      .btn {
        display: block;
        width: 130px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
      }
    }
  }

  .modal-footer {
    margin-top: 0;
  }
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

/* Reference Document Modal
 * -------------------------------------------------- */
#addDocument {
  .modal-body {
    padding: 10px;
  }

  .tree-area {
    width: 450px;
    select {
      width: 100%;
      height: 25px;
      font-size: 12pt;
      font-weight: 600;
    }
    #document-trees {
      max-height: 525px;
      height: 280px;

      a.toggle-data {
        i.folder-selected {
          color: white;
          margin-right: 5px;
          display: none;
        }
      }

      a.toggle-data:hover{
        background-color: #F0F1F3;
      }

      a.toggle-data.selected {
        background-color: #808080;
        span {
          color: white
        }

        i.folder-selected {
          display: block;
        }
      }

      .error-message {
        font-weight: bold;
      }

      .data-header {
        padding: 0;
        border: none;
        background: none;
      }

      .nested {
        margin-left: 15px;
      }

      .data-body {
        padding: 0;
      }
    }
  }




  .document-search {
    width: 500px;
    #document-input {
      width: 100%;
      height: 25px;
      font-size: 12pt;
      font-weight: 600;
    }
    #document-area {
      max-height: 525px;
      height: 280px;

      .data-header {
        background-color: white;
      }

      .data-body {
        padding: 0;
      }

      .leafNode {
        .topic-selected {
          color: white;
          margin-right: 5px;
        }

        .topic-selected {
          display: none;
        }
      }

      .leafNode:hover {
        background-color: #F0F1F3;
      }

      .leafNode.selected {
        background-color: #808080;
        span {
          color: white
        }

        .topic-selected {
          display: block;
        }
      }
    }
  }
}

